<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR')
                  ? `/proyectos/cns/administracion`
                  : `/proyectos/cns/externo/registro-informacion`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            Enmiendas de Montos
            <v-skeleton-loader
              v-if="skeletonLoading"
              min-height="50px"
              type="list-item"
            ></v-skeleton-loader>
            <v-subheader
              class="pl-0 font-weight-black text-h5 text--blue-grey text--lighten-4"
              v-if="!skeletonLoading"
            >
              {{ codigoProyecto }}
            </v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="light-blue-502"
              dark
              class="mb-2 float-right white--text"
              @click="modalNuevo"
            >
              <v-icon left>mdi-plus</v-icon>
              Nueva enmienda
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="enmiendas"
          :loading="tableLoading"
          :search="filtro"
          no-data-text="No se encontraron registros"
          no-results-text="No se encontraron registros con el filtro especificado"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Número de enmienda, descripción..."
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoSeleccionado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.orden }}</td>
              <td>
                {{ item.codigo }}
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  small
                  :color="
                    item.tipo === 'SIGEACI'
                      ? 'blue-grey lighten-4'
                      : 'blue lighten-4'
                  "
                  :text-color="
                    item.tipo === 'SIGEACI'
                      ? 'blue-grey lighten-1'
                      : 'blue lighten-1'
                  "
                >
                  {{ item.tipo }}
                </v-chip>
              </td>
              <td>{{ item.fechaCreacion | formatDate }}</td>
              <td>{{ item.accion }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'red lighten-4'
                      : item.estadosId === 3
                      ? 'blue lighten-4'
                      : item.estadosId === 4
                      ? 'cyan lighten-4'
                      : item.estadosId === 3
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'red lighten-1'
                      : item.estadosId === 4
                      ? 'cyan darken-1'
                      : item.estadosId === 3
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadosId === 1 ? `En registro` : item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 4) &&
                      item.tipo === `SICOOPERA`
                  "
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id, 1)"
                >
                  <v-icon left>{{
                    item.estadosId === 1 ? `mdi-pencil` : `mdi-eye`
                  }}</v-icon>
                  {{ item.estadosId === 1 ? `Actualizar` : `Visualizar` }}
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 3 && item.tipo === `SIGEACI`"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrardatosEnmiendaSigeaci(
                      item.id,
                      item.monto,
                      item.tipoCambio,
                      item.montoTotalQuetzales,
                      item.tiposDatosFinancierosId,
                      item.fechaCreacion,
                      item.estado,
                      item.accion,
                      item.moneda,
                      item.tipo
                    )
                  "
                >
                  <v-icon left>mdi-eye</v-icon>Visualizar
                </v-btn>

                <v-btn
                  v-if="
                    (item.estadosId === 4 || item.estadosId === 1) &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="mostrarModalInactivar(item.id, 2)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-tab-remove</v-icon>
                  Inactivar enmienda
                </v-btn>

                <!-- <v-btn
                  v-if="
                    item.estadosId === 2 &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="mostrarModalInactivar(item.id, 1)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-check-outline</v-icon>
                  Activar enmienda
                </v-btn> -->

                <template>
                  <div class="text-center float-left">
                    <v-dialog v-model="dialogAlertaValidacion" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="item.estadosId === 1"
                          color="deep-orange lighten-2"
                          text
                          fab
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          Alerta
                        </v-card-title>

                        <v-card-text>
                          <p class="text-h6 black--text">
                            Es necesario validar la enmienda para que esta surta
                            efecto
                          </p>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="light-blue-502"
                            class="white--text"
                            @click="dialogAlertaValidacion = false"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1280px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registrar enmienda de montos | {{ codigoProyecto }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetItems();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-card outlined>
            <v-card-text>
              <v-form
                ref="formDatosFinancieros"
                v-on:submit.prevent="registrarDatosIniciales"
                v-model="validFormDatosIniciales"
              >
                <v-row class="mt-4">
                  <v-col cols="12" md="4" sm="4" xs="6" class="pt-0  pb-0 mb-0">
                    <v-text-field
                      autocomplete="off"
                      class="required"
                      dense
                      :disabled="true"
                      filled
                      color="blue-grey lighten-2"
                      v-model="fechaActual"
                      label="Fecha de la enmienda"
                      maxlength="10"
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="4" xs="6" class="pt-0 pb-0">
                    <v-select
                      v-model="datosEnmienda.tipoAccion"
                      :items="tiposOperacionEnmiendas"
                      :loading="false"
                      :disabled="enmiendaRegistrada || enmiendaValidada"
                      dense
                      filled
                      class="required"
                      label="Tipo de operación"
                      :rules="[selectRequired('tipo de operación')]"
                      item-text="tipo"
                      item-value="id"
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                    xs="12"
                    class="pt-0 pb-0"
                    v-if="!enmiendaRegistrada && !enmiendaValidada"
                  >
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right mt-1"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormDatosIniciales"
                      :loading="btnRegistroEnmienda"
                    >
                      Registrar enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card
            class="mt-6"
            outlined
            v-if="enmiendaRegistrada || enmiendaValidada"
          >
            <v-card-text>
              <!-- inicio:: seccion datos financieros-->
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-subheader class="text-h6 black--text">
                    Montos de financiamiento
                  </v-subheader>
                  <v-divider class="mt-0 pt-1 pb-6"></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-1 pb-1">
                  <v-alert
                    v-if="
                      datosFinancieros.tiposDatosFinancierosId === 1 ||
                        datosFinancieros.tiposDatosFinancierosId === 2 ||
                        datosFinancieros.tiposDatosFinancierosId === 3"
                      outlined
                      type="info"
                      dense
                  >
                    Moneda de suscripción: 
                    {{
                    datosFinancieros.tiposDatosFinancierosId === 1 &&
                      monedaFCDf != ""
                        ? monedaFCDf
                        : datosFinancieros.tiposDatosFinancierosId === 2 &&
                          monedaCPDf != ""
                        ? monedacPDf
                        : datosFinancieros.tiposDatosFinancierosId === 3 &&
                          monedaOtrosDf != ""
                        ? monedaOtrosDf
                        : ``
                    }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="agregarDatoFinanciero"
                v-model="validFormDatosFinancieros"
              >
                <v-row>
                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                    <v-select
                      v-model="datosFinancieros.tiposDatosFinancierosId"
                      :items="tiposDatosFinancieros"
                      :loading="ddTiposDatosFinancierosLoading"
                      dense
                      filled
                      label="Datos financieros"
                      item-text="nombreTipoDatoFinanciero"
                      item-value="id"
                      :rules="[selectRequired('tipo de datos financieros')]"
                      :no-data-text="
                        tiposDatosFinancieros != null
                          ? 'Selecciona un tipo de dato financiero'
                          : 'No se han encontrado tipos de datos financieros'
                      "
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                    <v-autocomplete
                      v-model="monedaSeleccionada"
                      :items="monedas"
                      :loading="ddMonedasLoading"
                      dense
                      filled
                      label="Moneda"
                      :item-text="
                        (item) =>
                          item.nombreMoneda + ' (' + item.codigoMoneda + ')'
                      "
                      item-value="id"
                      return-object
                      :rules="[selectRequired('moneda')]"
                      :no-data-text="
                        monedas != null
                          ? 'Selecciona una moneda'
                          : 'No se han encontrado monedas'
                      "
                      menu-props="auto"
                      @change="
                        monedaSeleccionada.id === 125
                          ? (tipoCambio = `1`)
                          : (tipoCambio = ``)
                      "
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="montoMonedaOriginal"
                      :label="
                        `Monto moneda original (${
                          monedaSeleccionada.codigoMoneda
                            ? monedaSeleccionada.codigoMoneda
                            : 'Moneda no seleccionada'
                        })`
                      "
                      :rules="[
                        required('monto moneda original'),
                        decimals10('monto moneda original'),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="tipoCambio"
                      label="Tipo de cambio"
                      :rules="[
                        required('tipo de cambio'),
                        decimals10('tipo de cambio'),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      :disabled="true"
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      suffix="GTQ"
                      v-model="datosFinancieros.montoTotalQuetzales"
                      label="Monto total quetzales"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormDatosFinancieros"
                      :loading="btnDatosFinancierosLoading"
                    >
                      Agregar datos financieros
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-alert
                      outlined
                      type="warning"
                      color="orange darken-1"
                      dense
                    >
                      Si la moneda es Quetzales (GTQ) el tipo de cambio debe ser
                      igual a 1
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>

              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12">
                  <v-subheader class="text-subtitle-1 black--text">
                    Datos financieros modificados
                  </v-subheader>
                  <v-divider class="mt-0 pt-0"></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <!--inicio:: tabla datos financieros -->
                <v-col cols="12" md="12" sm="12" class="pt-2">
                  <v-data-table
                    outlined
                    dense
                    class="elevation-1"
                    :headers="headersDatosFinancieros"
                    :items="datosFinancierosProyecto"
                    :loading="tableDatosFinancierosLoading"
                    hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreTipoDatoFinanciero }}</td>
                        <td>{{ item.nombreMoneda }}</td>
                        <td class="text-right">
                          {{
                            `${item.codigoMoneda}  ${item.montoMonedaOriginal}`
                          }}
                        </td>
                        <td class="text-right">{{ item.tipoCambio }}</td>
                        <td class="text-right">
                          {{
                            parseFloat(
                              item.montoTotalQuetzales
                            ).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                            })
                          }}
                        </td>
                        <!-- <td class="text-right"> Q {{ parseFloat(item.montoTotalQuetzales) }}</td> -->
                        <td>
                          <v-btn
                            v-if="!enmiendaValidada"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            :disabled="btnEliminarDisabled"
                            color="blue-grey lighten-5"
                            @click="eliminarDatoFinanciero(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de datos financieros
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla datos financieros -->
                <!-- inicio: total de la secion -->

                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                  class="pt-0 pb-0 d-none"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto Fuente Cooperante Original</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancierosFC
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                  class="pt-0 pb-0 d-none"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto Contrapartida Original</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancierosCP
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                  class="pt-0 pb-0 d-none"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto total</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancieros
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->
              </v-row>
            </v-card-text>
          </v-card>
          <!-- fin:: seccion datos financieros-->

          <!-- inicio:: registro de nueva subdivision componente/objetivo/indicador/resultado/meta -->
          <v-card
            class="mt-6"
            outlined
            v-if="enmiendaRegistrada && !enmiendaValidada"
          >
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Registrar Nueva Subdivisión
                  </span>

                  <v-btn
                    class="float-right mx-2"
                    fab
                    dark
                    small
                    color="light-blue-502"
                    @click.stop="dialogAyuda = true"
                  >
                    <v-icon dark>mdi-help-circle-outline</v-icon>
                  </v-btn>

                  <!--<span class="text-muted mt-3 font-weight-bold font-size-md">
                                        (Componente, objetivo, indicador, resultado, meta)
                                    </span>-->
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formProyeccionDesembolsos"
                v-on:submit.prevent="agregarProyeccionSubDivision"
                v-model="validFormProyeccionSubDivision"
              >
                <v-row class="mt-">
                  <v-col cols="12" md="12" sm="12" class="pb-0">
                    <v-subheader class="text-h6 black--text pb-0">
                      Subdivisión del monto suscrito de la fuente cooperante
                    </v-subheader>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosEjecucion.actividadesEjecucionId"
                      :items="subDivisionesBase"
                      :loading="false"
                      dense
                      filled
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        subDivisiones != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-textarea
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosEjecucion.descripcion"
                      label="Descripción"
                      :rules="[
                        required('descripción de la subdivisión'),
                        maxLength('descripción de la subdivisión', 2500),
                      ]"
                      maxlength="2500"
                      rows="3"
                    ></v-textarea>
                  </v-col>

                  <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                                            <v-select
                                                v-model="aportaContrapartida"
                                                :items="aportaContrapartidaItems"
                                                :loading="false"
                                                dense
                                                :disabled="!contraPartidaRegistrada"
                                                filled 
                                                label="¿Registrar para contrapartida?"
                                                item-text="text"
                                                item-value="id"
                                                :no-data-text="
                                                    aportaContrapartidaItems.length > 0
                                                    ? 'Selecciona una opción'
                                                    : 'No se han encontrado opciones'
                                                "
                                                menu-props="auto"
                                            ></v-select>
                                        </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="
                        !validFormProyeccionSubDivision ||
                          datosFinancierosProyecto.length <= 0 || (datosEjecucion.actividadesEjecucionId == null || datosEjecucion.actividadesEjecucionId == 0)
                      "
                      :loading="btnRegistroEjecucionActividadLoading"
                    >
                      Agregar Subdivisión
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <!--  <v-row class="mt-">
                                
                                <v-col cols="12" md="12" sm="12" class="pb-0">
                                    <v-subheader class="text-h6 black--text pb-0">
                                        Monto suscrito de la fuente cooperante
                                    </v-subheader>
                                </v-col>
                            </v-row> -->

              <v-row>
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
                <v-col cols="12" md="12" sm="12" class="pt-4 d-none">
                  <v-data-table
                    class="elevation-1"
                    dense
                    :headers="headersSubDivisionesCooperante"
                    :items="proyeccionesSubDivision"
                    :loading="tableLoadingSubdivisionCooperante"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <!-- <td class="text-right">{{ parseFloat(item.aporteFuenteCooperante).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }}</td>
                                                
                                                <td>
                                                    <v-btn
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        color="blue-grey lighten-5"
                                                        :disabled="bntEliminarDistribucionCooperanteDisabled"
                                                        @click="eliminarDistribucionCooperante(item.id)">
                                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                                    </v-btn>
                                                </td> -->
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de fuente cooperante
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

                <!-- inicio: total de la secion -->
                <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6"
                        >{{
                          parseFloat(
                            totalSubDivisionCooperante
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalDatosFinancierosFC
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <!--fin:: total de la seccion -->
              </v-row>

              <v-row v-if="contraPartidaRegistrada">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pb-0">
                  <v-subheader class="text-h6 black--text pb-0">
                    Subdivisión del monto suscrito de la contrapartida
                  </v-subheader>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                  <v-form
                    ref="formProyeccionDesembolsos"
                    v-on:submit.prevent="
                      agregarProyeccionSubDivisionContrapartida(0)
                    "
                    v-model="validFormProyeccionSubDivisionContraPartida"
                  >
                    <v-row>
                      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-select
                          v-model="
                            datosEjecucionContraPartida.actividadesEjecucionId
                          "
                          :items="subDivisionesBase"
                          :loading="false"
                          dense
                          filled
                          label="Subdivisión"
                          item-text="nombreActividad"
                          item-value="id"
                          :no-data-text="
                            subDivisiones != null
                              ? 'Selecciona una subdivisión'
                              : 'No se han encontrado subdivisiones'
                          "
                          menu-props="auto"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-textarea
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="datosEjecucionContraPartida.descripcion"
                          label="Descripción"
                          :rules="[
                            required('descripción de la proyección'),
                            maxLength('descripción de la subdivisión', 2500),
                          ]"
                          maxlength="2000"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="
                            !validFormProyeccionSubDivisionContraPartida ||
                              datosFinancierosProyecto.length <= 0
                          "
                          :loading="
                            btnRegistroEjecucionActividadContraPartidaLoading
                          "
                        >
                          Agregar subdivisión
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>

                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA-->
                <v-col cols="12" md="12" sm="12" class="pt-4 d-none">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersSubDivisionesContrapartida"
                    :items="proyeccionesSubDivisionContrapartida"
                    :loading="tableLoadingSubdivisionContrapartida"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <!-- <td class="text-right">{{ parseFloat(item.monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }}</td>
                                                <td>
                                                    <v-btn
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        :disabled="btnEliminarDistribucionContrapartidaDisabled"
                                                        color="blue-grey lighten-5"
                                                        @click="eliminarDistribucionContrapartida(item.id)"
                                                        >
                                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                                    </v-btn>
                                                </td> -->
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de contrapartida
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA -->

                <!-- inicio: total de la secion -->
                <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Nacional</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            totalSubDivisionContrapartida
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalDatosFinancierosCP
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <!--fin:: total de la seccion -->
                <!-- inicio: total de la secion 
                                <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                                    <v-list-item two-line>
                                        <v-list-item-content class="pb-0 pt-0">
                                            <v-list-item-title class="text-h6">Total Desembolsos</v-list-item-title>
                                            <v-list-item-subtitle class="text-h6">{{parseFloat(totalSubDivisiones).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                fin:: total de la seccion -->
              </v-row>
            </v-card-text>
          </v-card>
          <!-- fin:: registro subdivisión componente/objetivo/indicador/resultado/meta -->

          <v-card
            outlined
            class="mt-4"
            v-if="enmiendaRegistrada || enmiendaValidada"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Asignación de componentes </span
                  ><br />
                  <span
                    class="gray--text mt-3 font-weight-bold text-subtitle-1"
                  >
                    Subdivisión, Componente, Objetivo, Indicador o Meta
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-2 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12" md="12" sm="12" class="pb-2">
                  <v-subheader class="text-subtitle-1 black--text pb-0">
                    Subdivisión del monto suscrito de la fuente cooperante
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row v-if="!fuenteCooperanteRegistrada">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    No existen datos financieros para la fuente coopoerante
                    registrados.
                  </v-alert>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="registrarEjecucionCooperante"
                v-model="validFormSubdivisionCooperante"
              >
                <v-row>
                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosSubdivision.ejecucionesPorActividadCnsId"
                      :items="subDivisiones"
                      :loading="false"
                      dense
                      filled
                      class="required"
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        subDivisiones != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                      :rules="[selectRequired('Subdivisión')]"
                    >
                      <template v-slot:selection="{ item }" class="pt-2">
                        <v-list-item
                          class="pl-0"
                          style="min-height: 40px !important;"
                        >
                          <v-list-item-content
                            style="padding: 2px 0 !important;"
                          >
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-5 pb-1 pl-0">
                                    <span>{{
                                      item.nombreActividad +
                                        " | " +
                                        item.descripcion
                                    }}</span>
                                  </v-col>
                                  <!--  <v-col cols="12" class="pt-1 pb-1">
                                                                    <span>{{ item.descripcion }}</span>
                                                                </v-col> -->
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                        <v-chip text-color="white" color="blue-grey darken-1" small>
                                                            {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                        </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!--  {{ item.nombreActividad + ' | ' + item.descripcion  + ' | ' + parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' }) }} -->
                      </template>

                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.nombreActividad }}</span>
                                  </v-col>
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.descripcion }}</span>
                                  </v-col>
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                        <v-chip text-color="white" color="blue-grey darken-1" small>
                                                            {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                        </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      :hint="
                        `Monto disponible ${parseFloat(
                          totalDatosFinancierosFC -
                            totalSubDivisionRegistradaCooperante
                        ).toLocaleString('es-GT', {
                          style: 'currency',
                          currency: 'GTQ',
                        })}`
                      "
                      suffix="GTQ"
                      v-model="montoSubdivisionFc"
                      label="Aporte Fuente Cooperante"
                      :rules="[
                        required('aporte de la fuente cooperante'),
                        decimals10('aporte de la fuente cooperante'),
                        maxLength('aporte de la fuente cooperante', 30),
                        maxNumber(
                          'aporte de la fuente cooperante',
                          parseFloat(
                            totalDatosFinancierosFC -
                              totalSubDivisionRegistradaCooperante
                          )
                        ),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormSubdivisionCooperante"
                      :loading="btnRegistroEjecucionFuenteCooperanteLoading"
                    >
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="fuenteCooperanteRegistrada">
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    class="elevation-1"
                    dense
                    :headers="headersSubDivisionesCooperante"
                    :items="subdivisionesEnmiendaFC"
                    :loading="tableLoadingSubdivisionCooperante"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td class="text-right">
                          {{
                            parseFloat(item.monto).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                            })
                          }}
                        </td>

                        <td>
                          <v-btn
                            v-if="!enmiendaValidada"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            :disabled="btnEliminarEjecucionFCDisabled"
                            @click="eliminarEjecucionCooperante(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de fuente cooperante
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6"
                        >{{
                          parseFloat(
                            totalSubDivisionRegistradaCooperante
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalDatosFinancierosFC
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                          })
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->
              </v-row>
              <v-row
                v-if="
                  totalSubDivisionRegistradaCooperante !=
                    totalDatosFinancierosFC
                "
                class="mt-2"
              >
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert outlined type="error" dense>
                    No se ha distribuido el monto total de los datos financieros
                    registrados en la subdivisión de la fuente cooperante
                  </v-alert>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12" class="pb-2">
                  <v-subheader class="text-subtitle-1 black--text pb-0">
                    Subdivisión del monto suscrito de la contrapartida y otros
                    aportes
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row v-if="!contraPartidaRegistrada && !otrosRegistrado">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    No existen datos financieros para la contrapartida y otros
                    aportes registrados.
                  </v-alert>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="registrarEjecucionContrapartida"
                v-model="validFormSubdivisionContrapartida"
              >
                <v-row v-if="contraPartidaRegistrada || otrosRegistrado">
                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-select
                      v-model="
                        datosSubdivisionContrapartida.contrapartidasActividadesId
                      "
                      :items="subDivisionesCP"
                      :loading="false"
                      dense
                      filled
                      class="required"
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        subDivisionesCP != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                      :rules="[selectRequired('Subdivisión')]"
                    >
                      <template v-slot:selection="{ item }" class="pt-2">
                        <v-list-item
                          class="pl-0"
                          style="min-height: 40px !important;"
                        >
                          <v-list-item-content
                            style="padding: 2px 0 !important;"
                          >
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-5 pb-1 pl-0">
                                    <span>{{
                                      item.nombreActividad +
                                        " | " +
                                        item.descripcion
                                    }}</span>
                                  </v-col>
                                  <!--  <v-col cols="12" class="pt-1 pb-1">
                                                                <span>{{ item.descripcion }}</span>
                                                            </v-col> -->
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                    <v-chip text-color="white" color="blue-grey darken-1" small>
                                                        {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                    </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!--  {{ item.nombreActividad + ' | ' + item.descripcion  + ' | ' + parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' }) }} -->
                      </template>

                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.nombreActividad }}</span>
                                  </v-col>
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.descripcion }}</span>
                                  </v-col>
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                            <v-chip text-color="white" color="blue-grey darken-1" small>
                                                                {{ parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                            </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      :hint="
                        `Monto disponible ${parseFloat(
                          totalContrapartidaOtros -
                            totalSubDivisionRegistradaContrapartida
                        ).toLocaleString('es-GT', {
                          style: 'currency',
                          currency: 'GTQ',
                        })}`
                      "
                      suffix="GTQ"
                      v-model="montoEjecutadoContrapartida"
                      label="Aporte Contrapartida"
                      :rules="[
                        required('aporte de la contrapartida'),
                        decimals10('aporte de la contrapartida'),
                        maxLength('aporte de la contrapartida', 30),
                        maxNumber(
                          'aporte de la contrapartida',
                          parseFloat(
                            totalContrapartidaOtros -
                              totalSubDivisionRegistradaContrapartida
                          )
                        ),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormSubdivisionContrapartida"
                      :loading="btnRegistroEjecucionContrapartidaLoading"
                    >
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="contraPartidaRegistrada || otrosRegistrado">
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA-->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersSubDivisionesContrapartida"
                    :items="subdivisionesEnmiendaCP"
                    :loading="tableLoadingSubdivisionContrapartida"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td class="text-right">
                          {{
                            parseFloat(item.monto).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                            })
                          }}
                        </td>
                        <td>
                          <v-btn
                            v-if="!enmiendaValidada"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            :disabled="
                              btnEliminarDistribucionContrapartidaDisabled
                            "
                            color="blue-grey lighten-5"
                            @click="eliminarDistribucionContrapartida(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de contrapartida
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Contrapartida y Otros Aportes</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6"
                        >{{
                          parseFloat(
                            totalSubDivisionRegistradaContrapartida
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalContrapartidaOtros
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                          })
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  totalSubDivisionRegistradaContrapartida !=
                    totalContrapartidaOtros
                "
                class="mt-2"
              >
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert outlined type="error" dense>
                    No se ha distribuido el monto total de los datos financieros
                    registrados en la subdivisión de la contrapartida y otros
                    aportes
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            outlined
            class="mt-4"
            v-if="enmiendaRegistrada || enmiendaValidada"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Documento de respaldo </span
                  ><br />
                  <span
                    class="gray--text mt-3 font-weight-bold text-subtitle-1"
                  >
                    Debe cargar el documento de respaldo para que la enmienda
                    sea aplicada
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-2 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="cargarArchivo"
                v-model="validFormValidarEnmienda"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0  pb-0 mb-0">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="datosValidacion.observaciones"
                      label="Observaciones"
                      :rules="[
                        required('observaciones'),
                        minLength('observaciones', 5),
                        maxLength('observaciones', 2000),
                      ]"
                      maxlength="2000"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <template>
                      <v-file-input
                        id="respaldoFile"
                        dense
                        filled
                        placeholder="Seleccionar archivo .pdf"
                        label="Seleccione el documento de respaldo"
                        append-icon="mdi-file-pdf-outline"
                        prepend-icon=""
                        accept="application/pdf,image/jpeg"
                        @change="onFileChange"
                        :rules="[fileRequired('documento de respaldo')]"
                        :show-size="1000"
                        ref="respaldoFile"
                        class="required"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" small label color="primary">
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="
                                                    text-overline
                                                    grey--text
                                                    text--darken-3
                                                    mx-2
                                                "
                          >
                            +{{ files.length - 2 }} archivo(s)
                          </span>
                        </template>
                      </v-file-input>
                    </template>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormValidarEnmienda"
                      :loading="btnValidarEnmienda"
                    >
                      Guardar datos enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="enmiendaValidada">
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <v-icon large color="green darken-2">
                    mdi-check-all
                  </v-icon>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <p class="text-h6">La enmienda ya se encuentra aplicada!</p>
                </v-col>
              </v-row>

              <v-row v-if="enmiendaValidada">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-8 text-center">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 mt-2"
                    @click="
                      descargarArchivo(datosEnmienda.pathDocumentoRespaldo)
                    "
                    :elevation="0"
                  >
                    <v-icon left>mdi-cloud-download</v-icon>
                    Descargar documento de respaldo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--inicio:: dialog que muestra los datos de enmienda de sigeaci-->
    <v-dialog
      v-model="dialogMostrarSigeaci"
      max-width="800px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Datos enmienda montos | {{ codigoProyecto }}
          <v-chip
            class="ma-2 font-weight-medium"
            label
            small
            :color="
              datosEnmiendaSigeaci.procedencia === 'SIGEACI'
                ? 'blue-grey lighten-4'
                : 'blue lighten-4'
            "
            :text-color="
              datosEnmiendaSigeaci.procedencia === 'SIGEACI'
                ? 'blue-grey lighten-1'
                : 'blue lighten-1'
            "
          >
            {{ datosEnmiendaSigeaci.procedencia }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="false"
            @click="dialogMostrarSigeaci = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Tipo de acción de la emienda</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    datosEnmiendaSigeaci.tipo
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Fecha de la emienda</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    datosEnmiendaSigeaci.fechaCreacion
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Tipo de dato financiero a la que aplica</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    datosEnmiendaSigeaci.tipoDatoFinanciero
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Monto aplicado</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 float-right"
                    >{{ datosEnmiendaSigeaci.monto }}
                    {{ datosEnmiendaSigeaci.moneda }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Tipo de Cambio</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 float-right"
                    >{{ datosEnmiendaSigeaci.tipoCambio }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Monto Total Quetzales</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 float-right"
                    >{{
                      parseFloat(
                        datosEnmiendaSigeaci.montoTotalQuetzales
                      ).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      })
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: enmienda sigeaci-->

    <!--begin:: dialog inactivar/activar enmienda -->
    <v-dialog
      v-model="dialogActualizarEstado"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ textoTituloCambioEstado }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnCambioEstadoLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActualizarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                {{ textoCambioEstado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogActualizarEstado = false"
            :disabled="btnCambioEstadoLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoEnmienda()"
            :loading="btnCambioEstadoLoading"
          >
            Sí, continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
//import { mapGetters } from "vuex";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import {
  OBTENER_DATOS_FINANCIEROS_CNS, 
  REGISTRAR_EJECUCION_ACTIVIDAD_CNS, 
  REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS
 } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  OBTENER_ENMIENDAS_MONTOS_CNS,
  REGISTRAR_ENMIENDA_MONTOS_CNS,
  OBTENER_ENMIENDA_MONTOS_CNS,
  VALIDAR_ENMIENDA_MONTOS_CNS,
  OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS,
  REGISTRAR_DATO_FINANCIERO_ENMIENDA_CNS,
  OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS,
  REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS,
  ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS,
  OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS,
  REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS,
  ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS,
  ACTUALIZAR_ESTADO_ENMIENDA_MONTOS_CNS
} from "@/core/services/store/proyectoscns/enmiendas/enmiendamontoscns.module";
//import { OBTENER_DATOS_FINANCIEROS_CNS} from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";
import DownloadFile from "@/core/untils/downloadfile.js";
moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

const mascaraQuetzales = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 10,
  includeThousandsSeparator: false,
  allowNegative: false
});

export default {
  name: "AdminEnmiendasMontos",
  components: {
    SnackAlert,
    DialogLoader
  },
  data() {
    return {
      validForm: false,
      enmiendaId: 0,
      enmiendaRegistrada: false,
      validFormDatosIniciales: false,
      btnRegistroEnmienda: false,
      tableLoading: false,
      validFormDatosFinancieros: false,
      datosFinancieros: {
        id: 0,
        tiposDatosFinancierosId: 0,
        proyectosCNSId: 0,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin"
      },
      btnDatosFinancierosLoading: false,
      tableDatosFinancierosLoading: false,
      validFormProyeccionSubDivision: false,
      skeletonLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo información....",
      btnProyeccionLoading: false,
      dialogRegistro: false,
      btnRegistroLoading: false,
      fechaActual: "",
      datosProyecto: {},
      enmiendas: [],
      tiposDatosFinancieros: [],
      monedas: [],
      ddTiposDatosFinancierosLoading: false,
      ddMonedasLoading: false,
      filtro: "",
      parameters: [],
      proyectoId: "",
      codigoProyecto: "",
      items: [],
      estados: [
        { value: null, text: "Todos" },
        { value: 1, text: "En registro" },
        { value: 2, text: "Inactiva" },
        { value: 4, text: "Confirmado" }
      ],
      monedaSeleccionada: {},
      mascaraQuetzales,
      montoMonedaOriginal: "",
      tipoCambio: "",
      datosFinancierosProyecto: [],
      fechaEnmienda: "",
      tiposDatoFinancieroSeleccionado: {},
      datosEnmienda: {
        fecha: "",
        tipoAccion: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      tiposOperacionEnmiendas: [],
      estadoSeleccionado: {},
      datosItem: {
        numeroEnmienda: "",
        fechaEnmienda: ""
      },
      subDivisiones: [],
      totalSubDivisionCooperante: 0.0,
      subDivision: {},
      datosSubdivision: {
        enmiendasMontosCnsId: 0,
        ejecucionesPorActividadCnsId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1,
      },
      subDivisionesCP: [],
      totalSubDivisionContraPartida: 0.0,
      datosSubdivisionContrapartida: {
        enmiendasMontosCnsId: 0,
        contrapartidasActividadesId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1
      },
      descripcionSubdivision: "",
      proyeccionesSubDivision: [],
      totalDatosFinancieros: "",
      monedaFuenteCooperante: "",
      monedaContraPartida: "",
      contraPartidaRegistrada: false,
      otrosRegistrado: false,
      fuenteCooperanteRegistrada: false,
      monedaOtros: "",
      totalDatosFinancierosFC: "",
      totalDatosFinancierosCP: "",
      monedasFuenteCooperanteId: 0,
      monedasContrapartidaId: 0,
      btnEliminarDisabled: false,
      bntEliminarDistribucionCooperanteDisabled: false,
      btnEliminarDistribucionContrapartidaDisabled: false,

      tableLoadingSubdivisionCooperante: false,
      proyeccionesSubDivisionContrapartida: [],
      tableLoadingSubdivisionContrapartida: false,
      datosFuenteCooperanteRegistrados: 0,
      datosContrapartidaRegistrados: 0,
      totalDatosFinancierosOtros: 0.0,
      totalContrapartidaOtros: 0.0,
      validFormSubdivisionCooperante: false,
      subdivisionesEnmiendaFC: [],
      totalSubDivisionRegistradaCooperante: 0.0,
      montoSubdivisionFc: "",
      btnRegistroEjecucionFuenteCooperanteLoading: false,
      btnEliminarEjecucionFCDisabled: false,

      validFormSubdivisionContrapartida: false,
      btnRegistroEjecucionContrapartidaLoading: false,
      subdivisionesEnmiendaCP: [],
      totalSubDivisionRegistradaContrapartida: 0.0,
      montoEjecutadoContrapartida: "",
      montoSubdivisionCP: "",

      validFormValidarEnmienda: false,
      btnValidarEnmienda: false,
      archivo: [],
      dialogAlertaValidacion: false,
      datosValidacion: {
        id: 0,
        pathDocumentoRespaldo: "",
        observaciones: ""
      },
      enmiendaValidada: false,
      datosEnmiendaSigeaci: {},
      dialogMostrarSigeaci: false,
      maxDigits: 2,
      dialogActualizarEstado: false,
      textoTituloCambioEstado: "",
      btnCambioEstadoLoading: false,
      textoCambioEstado: "",
      datosFinancierosProyectoPrincipal: [],
      monedaFCDf: "",
      monedaOtrosDf: "",
      monedaCPDf: "",
      datosEjecucion: {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosEjecucionContraPartida: {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      validFormProyeccionSubDivisionContraPartida: false,
      btnRegistroEjecucionActividadContraPartidaLoading: false,
      btnRegistroEjecucionActividadLoading: false,
      subDivisionesBase: [],
      ...validations
    };
  },

  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoSeleccionado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoSeleccionado.value;
    },

    resetItems() {
      this.datosFinancierosProyecto = [];
      this.subdivisionesEnmiendaFC = [];
      this.subdivisionesEnmiendaCP = [];
      this.totalDatosFinancierosOtros = 0.0;
      this.totalContrapartidaOtros = 0.0;
      this.totalDatosFinancierosFC = 0.0;
      this.enmiendaRegistrada = false;
      this.enmiendaValidada = false;
      this.resetItemsDatosFinancieros();
      this.resetItemsSubdivisionFc();
      this.resetItemsSubdivisionCP();
    },

    resetItemsDatosFinancieros() {
      this.montoMonedaOriginal = "";
      this.tipoCambio = "";
      this.datosFinancieros = {
        id: 0,
        tiposDatosFinancierosId: 0,
        enmiendasMontosCnsId: this.enmiendaId,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin"
      };
    },

    resetItemsSubdivisionFc() {
      this.montoSubdivisionFc = "";
      this.datosSubdivision = {
        enmiendasMontosCnsId: this.enmiendaId,
        ejecucionesPorActividadCnsId: 0,
        descripcion: "",
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1,
        actividadesEjecucionId: 0,
        tipoAccion: this.datosEnmienda.tipoAccion
      };
    },

    resetItemsSubdivisionCP() {
      this.montoEjecutadoContrapartida = "";
      this.montoSubdivisionCP = "";
      this.datosSubdivisionContrapartida = {
        enmiendasMontosCnsId: 0,
        contrapartidasActividadesId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1
      };
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    //Obtener la información de fechas
    async obtenerDatosProyecto(idProyecto) {
      this.datosProyecto = {};
      this.skeletonLoading = true;
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          seccion: "PlazosProyecto",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //this.cuentaCondicionDesembolsoSeleccionada.id = this.datosItem.condicionesDesembolsos;

            this.datosProyecto.fechaSuscripcionPlazos
              ? (this.fechaSuscripcionPlazos = moment(
                  this.datosProyecto.fechaSuscripcionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaSuscripcionPlazos = "");
            this.datosProyecto.fechaInicioPlazos
              ? (this.fechaInicioPlazos = moment(
                  this.datosProyecto.fechaInicioPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaInicioPlazos = "");
            this.datosProyecto.fechaAprobacionPlazos
              ? (this.fechaAprobacionPlazos = moment(
                  this.datosProyecto.fechaAprobacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaAprobacionPlazos = "");
            this.datosProyecto.fechaFinalizacionFaseEjecucion
              ? (this.fechaFaseEjecucionActual = moment(
                  this.datosProyecto.fechaFinalizacionFaseEjecucion,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionFaseEjecucion = "");
            this.datosProyecto.fechaFinalizacionPlazos
              ? (this.fechaFinalizacionActual = moment(
                  this.datosProyecto.fechaFinalizacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionPlazos = "");
            this.codigoProyecto = this.datosProyecto.codigo;
            if (this.datosProyecto.enmiendasFechas === 1) {
              this.fechaFaseEjecucionActual = this.datosProyecto.fechaFaseEjecucionNueva;
              this.fechaFinalizacionActual = this.datosProyecto.fechaFinalizacionNueva;
            }
            this.proyectoEncontrado = true;
            this.skeletonLoading = false;

            this.obtenerEnimendas(this.proyectoId);
            //this.skeletonLoading = false;

            //console.log(this.datosProyecto.condicionesDesembolsos)

            //console.log(this.cuentaCondicionDesembolsoSeleccionada.id)
            //this.habilitarCondicionesDesembolsos = true; condicionesDesembolsos
          } else {
            this.proyectoEncontrado = false;
            this.skeletonLoading = false;
            this.tableLoading = false;
          }
        })
        .catch(() => {
          this.datosProyecto = {};
          this.skeletonLoading = false;
        });
    },

    //Obtener las enmiendas registradas
    async obtenerEnimendas(proyectoId) {
      this.enmiendas = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_ENMIENDAS_MONTOS_CNS, { estadoId: 0, id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.enmiendas = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.enmiendas = [];
          this.tableLoading = false;
        });
    },

    //Registrar enmienda (datos generales)
    async registrarDatosIniciales() {
      this.btnRegistroEnmienda = true;
      this.datosEnmienda.proyectosCNSId = this.proyectoId;

      await this.$store
        .dispatch(REGISTRAR_ENMIENDA_MONTOS_CNS, { datos: this.datosEnmienda })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.enmiendaRegistrada = true;
            this.enmiendaId = res.data[0].id;
            this.datosEnmienda.codigo = res.data[0].codigo;
            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
            //this.datosMecanismoEjecucion.mecanismosEjecucionId = 0;
            //this.resetItems();
            //this.dialogRegistro = false;

            this.obtenerEnimendas(this.proyectoId);
            this.obtenerDatosItem(this.enmiendaId, 2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.enmiendaRegistrada = false;
          }
          this.btnRegistroEnmienda = false;
        })
        .catch(error => {
          this.btnRegistroEnmienda = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. Por favor, póngase en contacto con un administrador del sistema. ${error}`
          );
        });
    },

    //Obtener los datos de la enmienda. Tipo 1 desde tabla, tipo 2 desde dialog de formulario
    async obtenerDatosItem(id, tipo) {
      this.resetItemsEjecucionFuenteCooperante();
      this.resetItemsEjecucionContrapartida();
      this.datosEnmienda = {};
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";

      this.$store
        .dispatch(OBTENER_ENMIENDA_MONTOS_CNS, { id: id })
        .then(res => {
          if (res.status === 200) {
            this.enmiendaId = id;
            this.datosEnmienda = res.data;
            this.obtenerDatosFinancieros(id).then(() => {
              this.datosFinancieros.enmiendasMontosCnsId = id;
              this.datosSubdivision.enmiendasMontosCnsId = id;

              this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId).then(() => {
                this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId).then(
                  () => {
                    if (this.datosEnmienda.estadosId === 4) {
                      this.enmiendaValidada = true;
                    }

                    this.dialogLoaderVisible = false;

                    //Mostrar el dialgo si la petición viene de la tabla
                    if (tipo === 1) {
                      this.dialogRegistro = true;
                    }
                  }
                );
              });
            });
            this.enmiendaRegistrada = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.dialogLoaderVisible = false;
          }
        })
        .catch(() => {
          this.enmiendas = [];
          this.dialogLoaderVisible = false;
        });
    },

    //Mostrar los datos de las enmiendas provenientes del SIGEACI
    mostrardatosEnmiendaSigeaci(
      id,
      monto,
      tipoCambio,
      montoTotalQuetzales,
      tiposDatosFinancierosId,
      fechaCreacion,
      estado,
      accion,
      moneda,
      procedencia
    ) {
      this.datosEnmiendaSigeaci.id = id;
      this.datosEnmiendaSigeaci.monto = monto;
      this.datosEnmiendaSigeaci.tipoCambio = tipoCambio;
      this.datosEnmiendaSigeaci.montoTotalQuetzales = montoTotalQuetzales;
      this.datosEnmiendaSigeaci.moneda = moneda;
      this.datosEnmiendaSigeaci.tipoDatoFinanciero =
        tiposDatosFinancierosId === 1
          ? "Fuente Copperante"
          : tiposDatosFinancierosId > 2
          ? "Contrapartida"
          : "Otros aportes";
      this.datosEnmiendaSigeaci.fechaCreacion = moment(
        fechaCreacion,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
      this.datosEnmiendaSigeaci.estado = estado;
      this.datosEnmiendaSigeaci.tipo = accion;
      this.datosEnmiendaSigeaci.procedencia = procedencia;
      // this.datosEnmiendaSigeaci.tipo = tipo;
      this.dialogMostrarSigeaci = true;
    },

    async obtenerDatosFinancierosEnmiendas(id) {
      console.log(id);
    },

    //Obtener las subdivisiones de la fuente cooperante registradas para la enmienda
    async obtenerSubdivisionesFCEnmineda(id) {
      console.log(id);
    },

    //Obtener las subdivisiones de la contrapartida registradas para la enmienda
    async obtenerSubdivisionesCPEnmineda(id) {
      console.log(id);
    },

    //Obtener los tipos de operacion para la enmienda
    async obtenerTiposOperacionEnmiendas() {
      this.tiposOperacionEnmiendas = [];
      this.tiposOperacionEnmiendas = [
        { id: 1, tipo: "Ampliar" },
        { id: 2, tipo: "Reducir" }
      ];
    },

    //Obtener tipos de datos financieros
    async obtenerTiposDatosFinancieros() {
      this.tiposDatosFinancieros = [];

      this.ddTiposDatosFinancierosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposDatosFinancieros/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposDatosFinancieros = res.data;
          }
          this.ddTiposDatosFinancierosLoading = false;
        })
        .catch(() => {
          this.tiposDatosFinancieros = [];
          this.ddTiposDatosFinancierosLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    //Obtener tipos de datos financieros
    async obtenerDatosFinancieros(enmiendaId) {
      /* this.tiposDatosFinancieros = [];

            this.ddTiposDatosFinancierosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposDatosFinancieros/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposDatosFinancieros = res.data;

                }
                this.ddTiposDatosFinancierosLoading=false;
            })
            .catch(() => {
                this.tiposDatosFinancieros = [];
                this.ddTiposDatosFinancierosLoading=false;
            });*/
      this.ddTiposDatosFinancierosLoading = true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS, {
          id: enmiendaId,
          estadoId: 1,
        })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
              this.fuenteCooperanteRegistrada = true;
              this.monedasFuenteCooperanteId = searchObjectFC.monedasId;
            } else {
              this.fuenteCooperanteRegistrada = false;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;

            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
              this.monedasContrapartidaId = searchObjectCP.monedasId;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.monedaOtros = searchObjectOtros.codigoMoneda;
              this.otrosRegistrado = true;
            } else {
              this.otrosRegistrado = false;
            }

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 2
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //Sumar total otros
            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalContrapartidaOtros =
              this.totalDatosFinancierosCP + this.totalDatosFinancierosOtros;
          }
          this.ddTiposDatosFinancierosLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.datosFinancierosProyecto = [];
          this.ddTiposDatosFinancierosLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    /**
     * Obtener las subdivisiones asignadas a la enmienda fuente cooperante por proyecto y año
     */
    async obtenerSubdivisionesEnmiendaFC(enmiendaId) {
      this.subdivisionesEnmiendaFC = [];
      this.totalSubDivisionRegistradaCooperante = 0.0;
      await this.$store
        .dispatch(OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS, {
          estadoId: 1,
          id: enmiendaId
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesEnmiendaFC = res.data;
            this.totalSubDivisionRegistradaCooperante = this.subdivisionesEnmiendaFC.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subdivisionesEnmiendaFC = [];
        });
    },

    /**
     * Obtener las subdivisiones asignadas a la enmienda de contrapartida por proyecto y año
     */
    async obtenerSubdivisionesEnmiendaCP(enmiendaId) {
      this.subdivisionesEnmiendaCP = [];
      this.totalSubDivisionRegistradaContrapartida = 0.0;
      await this.$store
        .dispatch(OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS, {
          estadoId: 1,
          id: enmiendaId,
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesEnmiendaCP = res.data;
            this.totalSubDivisionRegistradaContrapartida = this.subdivisionesEnmiendaCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subdivisionesEnmiendaCP = [];
        });
    },

    //Obtener monedas
    async obtenerMonedas() {
      this.monedas = [];

      this.ddMonedasLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Moneda/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.monedas = res.data;
          }
          this.ddMonedasLoading = false;
        })
        .catch(() => {
          this.monedas = [];
          this.ddMonedasLoading = false;
        });
    },

    //Obtener subdivisiones (Componente, objetivo, indicador, resultado, meta )
    //Obtener subdivisiones/actividades (Componente, objetivo, indicador, resultado, meta ) fuente cooperante
    async obtenerSubDivisiones(proyectoId) {
      this.subDivisiones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `EjecucionesPorActividadCNS/all/1/${proyectoId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisiones = res.data;
            this.totalSubDivisionCooperante = this.subDivisiones.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisiones = [];
        });
    },

    //Obtener subdivisiones (Componente, objetivo, indicador, resultado, meta )
    async obtenerSubDivisionesBase() {
      this.subDivisionesBase = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ActividadesEjecucion/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisionesBase = res.data;
          }
          //this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.subDivisionesBase = [];
          //this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Obtener subdivisiones / actividades (Componente, objetivo, indicador, resultado, meta ) contrapartida
    async obtenerSubDivisionesContraPartida(proyectoId) {
      this.subDivisionesCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ContrapartidasActividades/all/1/${proyectoId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisionesCP = res.data;
            this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisionesCP = [];
        });
    },

    configurarMoneda() {
      this.monedaSeleccionada.id = this.tiposDatoFinancieroSeleccionado.monedasId;
      this.monedaSeleccionada.codigoMoneda = this.tiposDatoFinancieroSeleccionado.codigoMoneda;
      this.monedaSeleccionada.nombreMoneda = this.tiposDatoFinancieroSeleccionado.nombreMoneda;
    },
    //Obtener las enmiendas registradas
    async obtenerItems() {},

    modalNuevo() {
      this.dialogRegistro = true;
    },

    //Registrar una enmienda
    async registrarItem() {},

    //Agregar Datos financieros
    async agregarDatoFinanciero() {
      this.btnDatosFinancierosLoading = true;
      this.datosFinancieros.estadosId = 1;
      this.datosFinancieros.usuarioCreacion = "admin";
      this.datosFinancieros.proyectosCNSId = this.id;
      this.datosFinancieros.montoMonedaOriginal = this.montoMonedaOriginal;
      this.datosFinancieros.monedasId = this.monedaSeleccionada.id;
      this.datosFinancieros.tipoCambio = this.tipoCambio;

      //Validar que el primer registro sea fuente cooperante
      if (
        this.datoFinancieroFCRegistrado <= 0 &&
        this.datosFinancieros.tiposDatosFinancierosId != 1
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe registrar inicialmente información para la Fuente Cooperante`
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //Validar que si ya se encuentra contrapartida registrada, y se está registrando Otros aportes, se valide que lleve el mismo tipo de cambio y la misma moneda
      //aplica tambien a viceversa

      //Validar si el que se registra es otros aportes
      if (this.datosFinancieros.tiposDatosFinancierosId === 3) {
        //Buscar si hay contrapartida registrada
        var buscarContrapartida = this.datosFinancierosProyecto.find(
          item => item.tiposDatosFinancierosId === 2
        );
        // Si existe contrapartida, validar que la moneda y el tipo de cambio sea la misma
        if (buscarContrapartida) {
          if (
            this.datosFinancieros.monedasId != buscarContrapartida.monedasId
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `La moneda para Otros Aportes debe ser la misma que se registró para la contrapartida.`
            );
          }

          if (
            parseFloat(this.datosFinancieros.tipoCambio) !=
            parseFloat(buscarContrapartida.tipoCambio)
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `El tipo de campo para Otros Aportes debe ser el mismo que se registró para la contrapartida.`
            );
          }
        }
      }

      //Validar si el que se registra es contrapartida
      if (this.datosFinancieros.tiposDatosFinancierosId === 2) {
        //Buscar si hay contrapartida registrada
        var buscarOtrosAportes = this.datosFinancierosProyecto.find(
          item => item.tiposDatosFinancierosId === 3
        );
        // Si existe contrapartida, validar que la moneda y el tipo de cambio sea la misma
        if (buscarOtrosAportes) {
          if (this.datosFinancieros.monedasId != buscarOtrosAportes.monedasId) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `La moneda para Otros Aportes debe ser la misma que se registró para la contrapartida.`
            );
          }

          if (
            parseFloat(this.datosFinancieros.tipoCambio) !=
            parseFloat(buscarOtrosAportes.tipoCambio)
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `El tipo de campo para Otros Aportes debe ser el mismo que se registró para la contrapartida.`
            );
          }
        }
      }

      /*Buscar el tipo de dato financiero dentor del objeto*/
      var datoComparar = this.datosFinancierosProyecto.find(
        item =>
          item.tiposDatosFinancierosId ===
          this.datosFinancieros.tiposDatosFinancierosId
      );

      if (datoComparar) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El tipo de datos financiero que intenta agregar ya se encuentra registrado!.`
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //Validar monto que sea mayor a cero 00
      if (parseFloat(this.datosFinancieros.montoTotalQuetzales) <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "El monto debe ser mayor a 0.00"
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //console.log(this.datosCobertura);

      await this.$store
        .dispatch(REGISTRAR_DATO_FINANCIERO_ENMIENDA_CNS, {
          datos: this.datosFinancieros
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.obtenerDatosFinancieros(
              this.datosFinancieros.enmiendasMontosCnsId
            );
            this.resetItemsDatosFinancieros();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnDatosFinancierosLoading = false;
        })
        .catch(error => {
          this.btnDatosFinancierosLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro, por favor, póngase en contacto con un administrador del sistema. ${error}`
          );
        });

      this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
        (a, b) => a + (b["montoTotalQuetzales"] || 0),
        0
      );

      /* this.datosFinancierosProyecto.push({
                datosFinancierosId: this.datoFinanciero.id,
                nombreTipoDatoFinanciero: this.datoFinanciero.nombreTipoDatoFinanciero,
                monedasId: this.moneda.id,
                moneda: this.moneda.nombreMoneda,
                montoMonedaOriginal: this.montoMonedaOriginal,
                tipoCambio: this.tipoCambio,
                montoTotalQuetzales: this.montoTotalQuetzales
            });

            //Sumar la columna total Q
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

          
            //Habilitar tipos de datos financieros para distribución
            //this.tiposDatosFinancierosDistribucion.find( item => item.id === this.datoFinanciero.id).disabled = false;

            
             


            //Limpiar los campos
            this.datoFinanciero = {};
            this.moneda.id = '';
            this.moneda = {};
            this.montoMonedaOriginal = '';
            this.tipoCambio = '';
*/
    },

    ///Fuente cooperante
    //Registrar ejecucion fuente cooperante
    async registrarEjecucionCooperante() {
      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (
        parseFloat(this.datosSubdivision.monto) +
          parseFloat(this.totalEjecucionFC) >
        parseFloat(this.totalDatosFinancierosFC)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            this.datosSubdivision.monto
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
          })} +  ${parseFloat(this.totalEjecucionFC).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
          })}  
                                                                        sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
                                                                          this
                                                                            .totalDatosFinancierosFC
                                                                        ).toLocaleString(
                                                                          "es-GT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "GTQ",
                                                                          }
                                                                        )}`
        );

        return;
      }

      this.datosSubdivision.tipoAccion = this.datosEnmienda.tipoAccion;

      this.datosSubdivision.monto = parseFloat(this.montoSubdivisionFc);
      //this.datosSubdivision.iafcnsId = this.iaffId;
      this.btnRegistroEjecucionFuenteCooperanteLoading = true;
      //this.datosSubdivision.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS, {
          datos: this.datosSubdivision
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            
            this.resetItemsSubdivisionFc();
            this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId);
            //this.dialogRegistro = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de fuente cooperante
     */

    async eliminarEjecucionCooperante(id) {
      this.dialogLoaderVisible = true;
      this.btnEliminarEjecucionFCDisabled = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionFCDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionFCDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. Por favor, póngase en contacto con un administrador del sistema ${error}`
          );
        });
    },

    ///Contrapartida
    //Registrar ejecucion contrapartida
    async registrarEjecucionContrapartida() {
      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (
        parseFloat(this.montoEjecutadoContrapartida) +
          parseFloat(this.totalSubDivisionRegistradaContrapartida) >
        parseFloat(this.totalContraPartidaOtros)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            this.montoEjecutadoContrapartida
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
          })} +  ${parseFloat(this.totalEjecucionCP).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })}  
                                                                        sobrepasa al monto suscrito por la contra partida y otros aportes ${parseFloat(
                                                                          this
                                                                            .totalContraPartidaOtros
                                                                        ).toLocaleString(
                                                                          "es-GT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "GTQ"
                                                                          }
                                                                        )}`
        );

        return;
      }

      //this.datosEjecucionContrapartida.fechaEjecucion = moment(this.fechaEjecucionCP, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosSubdivisionContrapartida.montoEjecutado = parseFloat(
        this.montoEjecutadoContrapartida
      );

      this.datosSubdivisionContrapartida.enmiendasMontosCnsId = this.enmiendaId;
      this.datosSubdivisionContrapartida.tipoAccion = this.datosEnmienda.tipoAccion;

      this.datosSubdivisionContrapartida.monto = parseFloat(
        this.montoEjecutadoContrapartida
      );

      this.btnRegistroEjecucionContrapartidaLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS, {
          datos: this.datosSubdivisionContrapartida,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            //this.dialogRegistro = false;
            this.resetItemsSubdivisionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionContrapartidaLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionContrapartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de contrapartida
     */
    async eliminarDistribucionContrapartida(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar subdivion
    /*async agregarProyeccionSubDivision() {
      this.proyeccionesSubDivision.push({
        nombreSubdivision: this.subDivision.nombreSubDivision,
        descripcionSubdivision: this.descripcionSubdivision,
        aporteFuenteCooperante: this.aporteFuenteCooperante,
      });

      if (parseFloat(this.aporteContrapartida) > 0) {
        this.proyeccionesSubDivisionContrapartida.push({
          nombreSubdivision: this.subDivision.nombreSubDivision,
          descripcionSubdivision: this.descripcionSubdivision,
          aporteFuenteCooperante: this.aporteContrapartida,
        });
      }

      this.subDivision = {};
      this.descripcionSubdivision = "";
      this.aporteFuenteCooperante = "";
      this.aporteContrapartida = "";
    },*/

    //Agregar proyeccion de la ejecución por componente, objetivo, ind, res, meta
    async agregarProyeccionSubDivision() {
      this.datosEjecucion.proyectosCNSId = parseInt(this.proyectoId);

      this.aportaContrapartida === 1
        ? (this.datosEjecucion.contraPartida = 1)
        : (this.datosEjecucion.contraPartida = 0);
      this.btnRegistroEjecucionActividadLoading = true;

      this.datosEjecucion.aporteFuenteCooperante = 0;
      this.datosEjecucion.proyectosCNSId = this.proyectoId;
      this.datosEjecucion.enmiendasComponentesCnsId = this.enmiendaId;

      await this.$store
        .dispatch(REGISTRAR_EJECUCION_ACTIVIDAD_CNS, {
          datos: this.datosEjecucion,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            /////////////////////////////////////////////----/*/////////////
            this.resetItemsEjecucionFuenteCooperante();
            // let idEjecucionRegistrado=res.data;
            //Validar que se haya seleccionado si tampien tiene aporte a contrapartida
            if (
              this.aportaContrapartida === 1 &&
              this.contraPartidaRegistrada
            ) {
              this.agregarProyeccionSubDivisionContrapartida(1);
            } else {
              this.btnRegistroEjecucionActividadLoading = false;
              //this.obtenerEjecucionesPorActividad(this.proyectoId);
              this.obtenerSubDivisiones(this.proyectoId);
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
        })
        .catch(error => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Obtener las ejecuciones por actividdad de cns
    async obtenerEjecucionesPorActividad(proyectoId) {
      this.tableLoadingSubdivisionCooperante = true;
      this.proyeccionesSubDivision = [];
      //this.totalSubDivisionCooperante=0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CNS, {
          estadoId: 1,
          id: proyectoId,
        })
        .then(res => {
          console.logres;
          if (res.status === 200) {
            this.proyeccionesSubDivision = res.data;
            //this.totalSubDivisionCooperante = this.proyeccionesSubDivision.reduce((a,b) => a + (b['aporteFuenteCooperante'] || 0), 0);

            // console.log(this.totalSubDivisionCooperante)
          }
          this.tableLoadingSubdivisionCooperante = false;
        })
        .catch((err) => {
          console.log(err);
          this.proyeccionesSubDivision = [];
          this.tableLoadingSubdivisionCooperante = false;
        });
    },

    async obtenerEjecucionesPorActividadContrapartida(proyectoId) {
      this.tableLoadingSubdivisionContrapartida = true;
      this.proyeccionesSubDivisionContrapartida = [];
      //this.totalSubDivisionContrapartida = 0;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          estadoId: 1,
          id: proyectoId,
        })
        .then(res => {
          if (res.status === 200) {
            this.proyeccionesSubDivisionContrapartida = res.data;
            //this.totalSubDivisionContrapartida = this.proyeccionesSubDivisionContrapartida.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
          this.tableLoadingSubdivisionContrapartida = false;
        })
        .catch(() => {
          this.proyeccionesSubDivisionContrapartida = [];
          this.tableLoadingSubdivisionContrapartida = false;
        });
    },

    //Agregar proyeccion subdivision contrapartida
    async agregarProyeccionSubDivisionContrapartida(registroFuenteCooperante) {
      this.datosEjecucionContraPartida.proyectosCNSId = parseInt(
        this.proyectoId
      );

      let datosRegistroEjecucionContrapartida = {};
      if (registroFuenteCooperante === 1) {
        datosRegistroEjecucionContrapartida.actividadesEjecucionId = this.datosEjecucion.actividadesEjecucionId;
        datosRegistroEjecucionContrapartida.descripcion = this.datosEjecucion.descripcion;
        datosRegistroEjecucionContrapartida.enmiendasComponentesCnsId = this.enmiendaId;
        datosRegistroEjecucionContrapartida.proyectosCNSId = this.proyectoId;
        datosRegistroEjecucionContrapartida.usuarioCreacion = "Admin";
        datosRegistroEjecucionContrapartida.estadosId = 1;
        datosRegistroEjecucionContrapartida.monto = 0;
      } else {
        //Validar que la cantidad que se está registrando no sobrepase lo suscrito
        if (
          parseFloat(this.totalSubDivisionContrapartida) +
            parseFloat(this.datosEjecucionContraPartida.monto) >
          this.totalDatosFinancierosCP
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La cantidad que está registrando, sobrepasa el monto suscrito para la contrapartida (${this.totalDatosFinancierosCP})`
          );
          return;
        }

        datosRegistroEjecucionContrapartida = this.datosEjecucionContraPartida;
      }

      this.btnRegistroEjecucionActividadContraPartidaLoading = true;

      await this.$store
        .dispatch(REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          datos: datosRegistroEjecucionContrapartida,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //Validar si el registro viene desde fuente cooperante para obtener las proyecciones de FC
            if (registroFuenteCooperante === 1) {
              this.obtenerSubDivisiones(this.proyectoId);
            }
            this.obtenerSubDivisionesContraPartida(this.proyectoId);
            this.obtenerEjecucionesPorActividadContrapartida(this.proyectoId);
            //this.resetItemsEjecucion();
            this.resetItemsEjecucionContrapartida();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
        })
        .catch(error => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    resetItemsEjecucionFuenteCooperante() {
      this.datosEjecucion = {
        estadosId: 1,
        actividadesEjecucionId: 0,
        descripcion: "",
        usuarioCreacion: "sicoo"
      };
    },

    resetItemsEjecucionContrapartida() {
      this.datosEjecucionContraPartida = {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        usuarioCreacion: "sicoo"
      };
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnValidarEnmienda = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\enmiendas\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosValidacion.pathDocumentoRespaldo = res.pathArchivo;
            this.datosEnmienda.pathDocumentoRespaldo = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.validarEmienda();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnValidarEnmienda = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnValidarEnmienda = false;
        });
    },

    validarEmienda() {
      this.datosValidacion.id = this.enmiendaId;

      this.$store
        .dispatch(VALIDAR_ENMIENDA_MONTOS_CNS, this.datosValidacion)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.enmiendaValidada = true;
            this.obtenerEnimendas(this.proyectoId);
            //this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            //this.dialogRegistro = false;
            //this.resetItemsSubdivisionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnValidarEnmienda = false;
        })
        .catch(() => {
          this.btnValidarEnmienda = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    mostrarModalInactivar(id, nuevoEstadoId) {
      if (nuevoEstadoId === 2) {
        this.textoTituloCambioEstado = "Inactivar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de inactivar la enmienda seleccionada?";
      }
      if (nuevoEstadoId === 1) {
        this.textoTituloCambioEstado = "Activar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de activar nuevamente la enmienda seleccionada?";
      }
      this.idSeleccionado = id;
      this.estadoActualId = nuevoEstadoId;
      //Si el estado actual es confirmado, pasarlo a inactivo
      this.nuevoEstadoId = nuevoEstadoId;
      this.dialogActualizarEstado = true;
    },

    async actualizarEstadoEnmienda() {
      this.btnCambioEstadoLoading = true;
      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_ENMIENDA_MONTOS_CNS, {
          id: this.idSeleccionado,
          estadoId: this.nuevoEstadoId
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogActualizarEstado = false;
            this.obtenerEnimendas(this.proyectoId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnCambioEstadoLoading = false;
        })
        .catch(error => {
          //console.logerror
          this.dialogActualizarEstado = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido un error al intentar realizar la acción especificada, por favor, póngase en contacto con un administrador del sistema. REF:" +
              error
          );
          this.btnCambioEstadoLoading = false;
        });
    },
    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    },

    async obtenerDatosFinancierosProyecto(proyectoId) {
      this.datosFinancierosProyectoPrincipal = [];
      this.monedaFCDf = "";
      this.monedaOtrosDf = "";
      this.monedaCPDf = "";
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyectoPrincipal = res.data;

            let objMonedaFc = this.datosFinancierosProyectoPrincipal.find(element => element.tiposDatosFinancierosId == 1 );
            
            if (objMonedaFc)
              this.monedaFCDf = objMonedaFc.nombreMoneda + `(${objMonedaFc.codigoMoneda})`;

            let objMonedaCP = this.datosFinancierosProyectoPrincipal.find(element => element.tiposDatosFinancierosId == 2 );
            if (objMonedaCP)
              this.monedaCPDf = objMonedaCP.nombreMoneda + `(${objMonedaCP.codigoMoneda})`;

            let objMonedaOtros = this.datosFinancierosProyectoPrincipal.find(element => element.tiposDatosFinancierosId == 3 );
            if (objMonedaOtros)
              this.monedaOtrosDf = objMonedaCP.nombreMoneda + `(${objMonedaCP.codigoMoneda})`;

          }
        }).catch(error => {
          //console.logerror
          this.dialogActualizarEstado = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido un error al intentar realizar la acción especificada, por favor, póngase en contacto con un administrador del sistema. REF:" +
              error
          );
          //this.btnCambioEstadoLoading = false;
        });
    }

  },

  created() {
    let currentDate = new Date().toJSON().slice(0, 10);
    this.fechaActual = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    this.parameters = this.$route.params;
    this.proyectoId = this.$CryptoJS.AES.decrypt(
      this.parameters.proyectoId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);
    //this.codigoProyecto = this.parameters.codigoProyecto;
    this.obtenerSubDivisiones(this.proyectoId);
    this.obtenerDatosProyecto(this.proyectoId);
    this.obtenerDatosFinancierosProyecto(this.proyectoId);
    this.obtenerTiposOperacionEnmiendas();
    this.obtenerTiposDatosFinancieros(this.proyectoId);
    this.obtenerSubDivisionesContraPartida(this.proyectoId);
    this.obtenerMonedas();
    this.obtenerSubDivisionesBase();
    //Generar la fecha

    var today = new Date();

    var date =
      +today.getDate() +
      "/" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "/" +
      today.getFullYear();
    this.datosItem.fechaEnmienda = date;
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos CNS" },
      { title: "Enmiendas" }
    ]);
  },

  computed: {
    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },

    headers() {
      return [
        {
          text: "Orden",
          align: "start",
          sortable: true,
          value: "Orden"
        },
        {
          text: "Código de enmienda",
          align: "start",
          sortable: true,
          value: "numeroEnmienda"
        },
        {
          text: "Fecha enmienda",
          align: "start",
          sortable: true,
          value: "fechaCreacion"
        },
        {
          text: "Tipo de operación",
          align: "start",
          sortable: true,
          value: "accion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadosId",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        },
      ];
    },

    headersDatosFinancieros() {
      return [
        {
          text: "Datos Financieros",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero"
        },
        {
          text: "Moneda",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
        {
          text: "Monto Original",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
        {
          text: "Monto total en quetzales",
          align: "start",
          sortable: true,
          value: "montoTotalQuetzales"
        },
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
      ];
    },
    headersSubDivisionesCooperante() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero"
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          value: "monto"
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
      ];
    },

    headersSubDivisionesContrapartida() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero"
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda"
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda"
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda"
        }
      ];
    }
  },

  watch: {
    montoMonedaOriginal: function() {
      this.datosFinancieros.montoTotalQuetzales =
        this.montoMonedaOriginal * this.tipoCambio;
    },

    tipoCambio: function() {
      this.datosFinancieros.montoTotalQuetzales =
        this.montoMonedaOriginal * this.tipoCambio;
    }
  }
};
</script>
